import React from 'react';
import { ErrorType } from '../context/ErrorContext';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  error?: ErrorType;
}

export function ErrorModal({ isOpen, onClose, error }: ErrorModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold text-red-600 mb-4">Error</h2>
        <div>
          {error?.code && <div>Error {error.code}</div>}
          {error?.statusText && <div>{error.statusText}</div>}
          {error?.context && <div>{error.context}</div>}
        </div>
        <button
          onClick={onClose}
          className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
        >
          Close
        </button>
      </div>
    </div>
  );
}

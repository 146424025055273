import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Task as WorkTask } from '../../models/general';
import { updateTask } from '../../utils/api';

// Utility to generate a color based on milestone title
const getColorForTitle = (title: string | undefined) => {
  if (!title) {
    return '#CCCCCC'; // Default color for undefined titles
  }

  // Define color mapping for milestone titles
  const colorMap: { [key: string]: string } = {
    // Light blue group
    'Estimate Completed': '#ADD8E6',
    'Letter Agreement Sent': '#ADD8E6',
    'Letter Agreement Signed': '#ADD8E6',
    'Deposit Sent': '#ADD8E6',

    // Dark blue group
    'Paid Deposit': '#0066CC',
    'Project Scheduled': '#0066CC',
    'Work Started': '#0066CC',

    // Light green
    'Schematic Delivery': '#90EE90',

    // Dark green
    '100% Plans Delivery': '#228B22',

    // Dark yellow
    'AHJ Permit Approved': '#FFD700',
  };

  return colorMap[title] || '#CCCCCC'; // Return mapped color or default gray
};

interface DashboardGanttChartProps {
  tasks: WorkTask[];
  setTasks: (tasks: WorkTask[]) => void;
  onTaskDoubleClick: (task: WorkTask) => void;
}

const DashboardGanttChart: React.FC<DashboardGanttChartProps> = ({
  tasks,
  onTaskDoubleClick,
  setTasks,
}) => {
  const navigate = useNavigate();
  const [showAHJMilestones, setShowAHJMilestones] = useState(true);
  const [showEstimateMilestones, setShowEstimateMilestones] = useState(true);
  const [showAgreementMilestones, setShowAgreementMilestones] = useState(true);
  const [showDeliveryMilestones, setShowDeliveryMilestones] = useState(true);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
  const convertTasks = (tasks: WorkTask[]): Task[] => {
    // Return a default task if there are no tasks
    if (!tasks || tasks.length === 0) {
      const today = new Date();
      return [
        {
          id: 'default',
          name: 'No milestones available',
          start: today,
          end: new Date(today.getTime() + 24 * 60 * 60 * 1000),
          type: 'task',
          progress: 0,
          styles: { backgroundColor: '#CCCCCC' }, // Default color
        },
      ];
    }

    let ganttTasks: Task[] = [];

    // Group tasks by milestone
    const milestoneGroups = tasks.reduce(
      (groups, task) => {
        const milestoneId = task.milestone?._id || 'unassigned';
        if (!groups[milestoneId]) {
          groups[milestoneId] = {
            milestone: task.milestone,
            project: task.project,
            tasks: [],
          };
        }
        groups[milestoneId].tasks.push(task);
        return groups;
      },
      {} as Record<string, { milestone: any; project: any; tasks: WorkTask[] }>,
    );

    // Create Gantt tasks for each milestone only
    Object.entries(milestoneGroups).forEach(
      ([milestoneId, { milestone, project, tasks: milestoneTasks }]) => {
        if (milestoneId === 'unassigned') return;
        if (milestone.completionDate) return; // Skip completed milestones

        // Filter milestones based on their type
        if (!showAHJMilestones && milestone.title === 'AHJ Permit Approved')
          return;
        if (!showEstimateMilestones && milestone.title === 'Estimate Completed')
          return;
        if (
          !showAgreementMilestones &&
          (milestone.title === 'Letter Agreement Sent' ||
            milestone.title === 'Letter Agreement Signed' ||
            milestone.title === 'Deposit Sent' ||
            milestone.title === 'Paid Deposit' ||
            milestone.title === 'Work Started')
        )
          return;
        if (
          !showDeliveryMilestones &&
          (milestone.title === 'Schematic Delivery' ||
            milestone.title === '100% Plans Delivery')
        )
          return;

        const milestoneStartDates = milestoneTasks
          .map((task) => new Date(task.startDate))
          .filter((date) => !isNaN(date.getTime()));

        const milestoneEndDates = milestoneTasks
          .map(
            (task) =>
              new Date(
                new Date(task.startDate).getTime() +
                  (task.duration || 1) * 24 * 60 * 60 * 1000,
              ),
          )
          .filter((date) => !isNaN(date.getTime()));

        if (milestoneStartDates.length === 0 || milestoneEndDates.length === 0)
          return;

        // Add milestone as a task
        ganttTasks.push({
          id: `milestone-${milestoneId}`,
          name: `${project?.name || 'Unknown Project'} - ${milestone.title} (${milestoneTasks.length} tasks)`,
          start: new Date(
            Math.min(...milestoneStartDates.map((d) => d.getTime())),
          ),
          end: new Date(Math.max(...milestoneEndDates.map((d) => d.getTime()))),
          type: 'task',
          progress: 0,
          styles: { backgroundColor: getColorForTitle(milestone.title) }, // Apply color based on title
        });

        // Function to sort ganttTasks by start date
        const sortGanttTasksByStart = (tasks: Task[]) => {
          return tasks.sort((a, b) => a.start.getTime() - b.start.getTime());
        };

        // Sort the ganttTasks by start date
        ganttTasks = sortGanttTasksByStart(ganttTasks);
      },
    );

    // If no valid milestones were created, return a default task
    if (ganttTasks.length === 0) {
      const today = new Date();
      return [
        {
          id: 'default',
          name: 'No valid milestones available',
          start: today,
          end: new Date(today.getTime() + 24 * 60 * 60 * 1000),
          type: 'task',
          progress: 0,
          styles: { backgroundColor: '#CCCCCC' }, // Default color
        },
      ];
    }

    return ganttTasks;
  };

  const convertedTasks = useMemo(
    () => convertTasks(tasks),
    [
      tasks,
      showAHJMilestones,
      showEstimateMilestones,
      showAgreementMilestones,
      showDeliveryMilestones,
    ],
  );

  const handleGanttDoubleClick = (ganttTask: Task) => {
    const milestoneId = ganttTask.id.replace('milestone-', '');
    const milestoneTasks = tasks.filter(
      (task) => task.milestone?._id === milestoneId,
    );
    if (milestoneTasks.length > 0) {
      // Get the project ID from the first task
      const projectId = milestoneTasks[0].project?._id;
      if (projectId) {
        // Navigate to project page using React Router
        navigate(`/project/${projectId}/schedule`);
      }
    }
  };

  const handleTaskChange = async (updatedTask: Task) => {
    const milestoneId = updatedTask.id.replace('milestone-', '');
    const milestoneTasks = tasks.filter(
      (task) => task.milestone?._id === milestoneId,
    );

    if (milestoneTasks.length > 0) {
      // Calculate time shift
      const timeDiff =
        updatedTask.start.getTime() -
        new Date(milestoneTasks[0].startDate).getTime();
      console.log('timeDiff', timeDiff);
      // Update all tasks in the milestone with new dates
      const updatedTasks = milestoneTasks.map((task) => ({
        ...task,
        startDate: new Date(new Date(task.startDate).getTime() + timeDiff),
      }));

      // Save to backend
      try {
        const updatePromises = updatedTasks.map((task) =>
          updateTask(task._id!, task),
        );
        const responses = await Promise.all(updatePromises);
        console.log('responses', responses);
        // Update local state
        setTasks(
          tasks.map((task) => {
            const updatedTask = responses.find((r) => r.data._id === task._id);
            if (updatedTask) {
              console.log('updatedTask', updatedTask);
            }
            return updatedTask
              ? { ...task, startDate: updatedTask.data.startDate }
              : task;
          }),
        );
      } catch (error) {
        console.error('Failed to update milestone tasks', error);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          <button
            className={`px-4 py-2 rounded-md transition-colors ${
              showEstimateMilestones
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setShowEstimateMilestones(!showEstimateMilestones)}
          >
            {showEstimateMilestones ? 'Hide' : 'Show'} Estimate Milestones
          </button>
          <button
            className={`px-4 py-2 rounded-md transition-colors ${
              showAgreementMilestones
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setShowAgreementMilestones(!showAgreementMilestones)}
          >
            {showAgreementMilestones ? 'Hide' : 'Show'} Agreement Milestones
          </button>
          <button
            className={`px-4 py-2 rounded-md transition-colors ${
              showDeliveryMilestones
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setShowDeliveryMilestones(!showDeliveryMilestones)}
          >
            {showDeliveryMilestones ? 'Hide' : 'Show'} Delivery Milestones
          </button>
          <button
            className={`px-4 py-2 rounded-md transition-colors ${
              showAHJMilestones
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setShowAHJMilestones(!showAHJMilestones)}
          >
            {showAHJMilestones ? 'Hide' : 'Show'} AHJ Permit Milestones
          </button>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <label htmlFor="timeframe" className="text-gray-700 font-medium">
            Time Frame:
          </label>
          <select
            id="timeframe"
            className="px-4 py-2 rounded-md border border-gray-300"
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value as ViewMode)}
          >
            <option value={ViewMode.Day}>Day</option>
            <option value={ViewMode.Week}>Week</option>
            <option value={ViewMode.Month}>Month</option>
          </select>
        </div>
      </div>
      <Gantt
        tasks={convertedTasks}
        viewMode={viewMode}
        onDoubleClick={handleGanttDoubleClick}
        onDateChange={handleTaskChange}
        listCellWidth={'0'}
        todayColor="#0066FF"
      />
    </div>
  );
};

export default DashboardGanttChart;

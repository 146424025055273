import { useNavigate } from 'react-router-dom';
import logo from '../images/abacusLogo.png';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeCompanyState } from '../atoms/new.company.atom';
import ConstructionCostEstimate from './ConstructionCostEstimate';
import { clearToken } from '../utils/tokenManager';
import { useState } from 'react';

const SideNav = () => {
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const [showEstimator, setShowEstimator] = useState(false);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const signOut = async () => {
    try {
      clearToken();
      setActiveUser(null);
      setActiveCompany(null);
      navigate('/');
    } catch (error) {
      console.error('Signin failed', error);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="text-white pt-2 grid grid-cols-1">
      <h1>
        {activeUser.firstName} {activeUser.lastName}
      </h1>
      {activeCompany ? (
        <h2 className="p-4 mb-10">{activeCompany.name}</h2>
      ) : (
        <h2>Company Name</h2>
      )}
      {/* <button
        onClick={() => navigate('/signup')}
        className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
      >
        Sign Up
      </button> */}
      <h1>Account</h1>
      {activeUser?.firstName ? (
        <>
          <button
            onClick={() => navigate('/profile')}
            className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            {activeUser ? <p>Profile</p> : <p>User Name</p>}
          </button>
          <button
            onClick={() => navigate('/')}
            className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            My Tasks
          </button>
          <button
            onClick={() => signOut()}
            className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            Log Out
          </button>
        </>
      ) : (
        <li>
          <a href="/login">Login</a>
        </li>
      )}
      <h1>Projects</h1>
      <button
        onClick={() => navigate('/project')}
        className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
      >
        + New Project
      </button>
      <button
        onClick={() => navigate('/projectList')}
        className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
      >
        Project List
      </button>

      <br />

      <button
        onClick={() => setShowEstimator(!showEstimator)}
        className="w-full px-4 py-1 text-sm text-white font-semibold  "
      >
        View Estimator
      </button>
      {showEstimator && <ConstructionCostEstimate />}
      <br />
      {activeAuthorizations.hasOwner && (
        <>
          <h1>Admin</h1>
          <button
            onClick={() => navigate('/companies')}
            className="w-full px-4 py-1 text-sm text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            Admin Dashboard
          </button>
        </>
      )}
    </div>
  );
};
export default SideNav;

import React, { useEffect } from 'react';
import { useError } from '../context/ErrorContext';

export function ApiErrorBoundary({ children }: { children: React.ReactNode }) {
  const { showError } = useError();

  useEffect(() => {
    const handleApiError = (
      event: CustomEvent<{ statusText: string; data: string }>,
    ) => {
      showError(event.detail);
    };

    // Add event listener
    window.addEventListener('api-error', handleApiError as EventListener);

    // Cleanup
    return () => {
      window.removeEventListener('api-error', handleApiError as EventListener);
    };
  }, [showError]);

  return <>{children}</>;
}

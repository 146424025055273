export const classificationOptions: string[] = [
  'commercial',
  'government',
  'residential',
];

export enum UserAuths {
  Accounting = 'Accounting',
  Estimating = 'Estimating',
  Legal = 'Legal',
  Designing = 'Designing',
}

export enum UserType {
  'owner' = 'Owner',
  'employee' = 'Employee',
  'customer' = 'Customer',
}

export enum DisciplineType {
  'architecture' = 'architecture',
  'civil' = 'civil',
  'electrical' = 'electrical',
  'foundation' = 'foundation',
  'mechanical' = 'mechanical',
  'plumbing' = 'plumbing',
  'structural' = 'structural',
  'survey' = 'survey',
  'admin' = 'admin',
}

enum FileClassification {
  'legal' = 'Legal',
  'insurance' = 'insurance',
  'license' = 'licenses',
  'contract' = 'contract',
  'technical' = 'technical',
}

export enum SubscriptionLevel {
  Guest = 'Guest',
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
}

export interface Template {
  company: string;
  title: string;
  type: string;
  list: object[];
}
export interface LOEItemI {
  discipline?: string;
  title: string;
  hoursPE: number;
  hoursEIT: number;
  hoursDraftsman: number;
  hoursAdmin: number;
  dollarsSub: number;
  dollarsMilage: number;
}

export interface Task {
  title: string;
  project: string | Project;
  company?: string;
  description?: string;
  status?: string;
  priority?: string;
  assignee?: string | User;
  scope?: string;
  reporter?: string | User;
  createdAt?: Date;
  updatedAt?: Date;
  startDate?: Date;
  duration?: number;
  milestone?: Milestone;
  dueDate?: Date;
  labels?: string[];
  subtasks?: Subtask[];
  comments?: Comment[];
  history?: History[];
}

export interface Comment {
  author: string;
  body: string;
  createdAt: Date;
}

export interface Subtask {
  title: string;
  description: string;
  status: string;
  assignee: string;
}

export interface History {
  field: string;
  oldValue: string;
  newValue: string;
  changedAt: Date;
  changedBy: string;
}

export interface Milestone {
  _id?: string;
  project?: string;
  title: string;
  sequence?: number;
  completionDate?: Date;
  targetDate?: Date;
}

export type FileDocument = {
  name: string;
  url: string;
  classification: FileClassification;
  source?: string;
};

export type HourlyRate = {
  name: string; // engineer
  type: string; // hourly
  amount: number; // 165
};

export type Message = {
  author: User;
  content: string;
};

export type Thread = {
  _id: string;
  title: string;
  messages: Message[];
};

export enum CompanyType {
  Engineer = 'Engineer',
  Contractor = 'Contractor',
  Architect = 'Architect',
  Client = 'Client',
  Electrician = 'Electrician',
  Plumber = 'Plumber',
  HVAC = 'HVAC',
  Roofing = 'Roofing',
  Flooring = 'Flooring',
  Masonry = 'Masonry',
  Painting = 'Painting',
  Drywall = 'Drywall',
  Landscaping = 'Landscaping',
  Excavation = 'Excavation',
  Demolition = 'Demolition',
  Surveyor = 'Surveyor',
  SteelFabricator = 'Steel Fabricator',
  Carpenter = 'Carpenter',
  Concrete = 'Concrete',
  Insulation = 'Insulation',
  FireProtection = 'Fire Protection',
  SecuritySystems = 'Security Systems',
  Elevator = 'Elevator',
  InteriorDesigner = 'Interior Designer',
  BuildingInspector = 'Building Inspector',
  Other = 'Other',
}

export type Project = {
  _id: string;
  company: string | Company;
  name: string;
  uniqueName: string; // client-address-name
  classification: string;
  estimatedConstructionCost: number;
  description: string;
  isUnplatted: boolean;
  unplattedAddress: string;
  address: string;
  disciplines: string[];
  client: Company;
  shouldBillOwner: boolean;
  owner?: Company;
  contacts?: User[];
  lead?: User;
  workDocuments?: FileDocument[];
  clientDocuments?: FileDocument[];
  deliverableDocuments?: FileDocument[];
  loeEstimate?: LOEItemI[];
  agreementContent?: string;
  sequnce?: number;
  workFlowStatus?: string;
  milestones?: Milestone[];
  schedule?: object[];
  signedContracts?: string[];
  threads?: Thread[];
};

export type Company = {
  _id?: string;
  subscriptionLevel: SubscriptionLevel;
  stripeCustomerId?: string;
  name: string;
  owner: User;
  email: string;
  phone: string;
  address: string;
  addressStreet?: string;
  billingCity?: string;
  billingZip?: string;
  billingState?: string;
  logoUrl?: string;
  website?: string;
  companyType?: CompanyType;
  users?: User[];
  documents?: FileDocument[];
  billingRates?: HourlyRate[];
  projects?: Project[];
};

export type User = {
  _id: string;
  active: boolean;
  company?: Company;
  imageUrl: string;
  username: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  userType: string;
  authorizations?: string[];
  recoveryToken?: string;
};

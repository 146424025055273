import './App.css';
import '@fontsource/inter';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { ErrorProvider } from './context/ErrorContext';
import { ApiErrorBoundary } from './components/ApiErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorProvider>
        <ApiErrorBoundary>
          <RecoilRoot>
            <Router>
              <AppRoutes />
            </Router>
          </RecoilRoot>
        </ApiErrorBoundary>
      </ErrorProvider>
    </QueryClientProvider>
  );
}

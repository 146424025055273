import React, { useState } from 'react';
import { Task as WorkTask } from '../../models/general';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { MdPersonOutline } from 'react-icons/md';

interface TaskListProps {
  tasks: WorkTask[];
  onEdit: (task: WorkTask) => void;
  onDelete?: (taskId: string) => void;
  onUpdateStatus: (taskId: string, newStatus: string) => void;
}

const statuses = ['To Do', 'In Progress', 'In Review', 'Done', 'Blocked'];

const ProjectTaskList: React.FC<TaskListProps> = ({
  tasks,
  onEdit,
  onDelete,
  onUpdateStatus,
}) => {
  const [showAHJMilestones, setShowAHJMilestones] = useState(true);
  const [showEstimateMilestones, setShowEstimateMilestones] = useState(true);
  const [showAgreementMilestones, setShowAgreementMilestones] = useState(true);
  const [showDeliveryMilestones, setShowDeliveryMilestones] = useState(true);

  const filteredTasks = tasks.filter((task) => {
    const milestoneTitle = task.milestone?.title;
    if (!milestoneTitle) return true;

    if (!showAHJMilestones && milestoneTitle === 'AHJ Permit Approved')
      return false;
    if (!showEstimateMilestones && milestoneTitle === 'Estimate Completed')
      return false;
    if (
      !showAgreementMilestones &&
      (milestoneTitle === 'Letter Agreement Sent' ||
        milestoneTitle === 'Letter Agreement Signed' ||
        milestoneTitle === 'Deposit Sent' ||
        milestoneTitle === 'Paid Deposit' ||
        milestoneTitle === 'Work Started')
    )
      return false;
    if (
      !showDeliveryMilestones &&
      (milestoneTitle === 'Schematic Delivery' ||
        milestoneTitle === '100% Plans Delivery')
    )
      return false;

    return true;
  });

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const taskId = result.draggableId;
      const newStatus = destination.droppableId;
      onUpdateStatus(taskId, newStatus);
    }
  };

  return tasks.length > 0 ? (
    <>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
        }}
      >
        <button
          className={`px-4 py-2 rounded-md transition-colors ${
            showEstimateMilestones
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setShowEstimateMilestones(!showEstimateMilestones)}
        >
          {showEstimateMilestones ? 'Hide' : 'Show'} Estimate Milestones
        </button>
        <button
          className={`px-4 py-2 rounded-md transition-colors ${
            showAgreementMilestones
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setShowAgreementMilestones(!showAgreementMilestones)}
        >
          {showAgreementMilestones ? 'Hide' : 'Show'} Agreement Milestones
        </button>
        <button
          className={`px-4 py-2 rounded-md transition-colors ${
            showDeliveryMilestones
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setShowDeliveryMilestones(!showDeliveryMilestones)}
        >
          {showDeliveryMilestones ? 'Hide' : 'Show'} Delivery Milestones
        </button>
        <button
          className={`px-4 py-2 rounded-md transition-colors ${
            showAHJMilestones
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setShowAHJMilestones(!showAHJMilestones)}
        >
          {showAHJMilestones ? 'Hide' : 'Show'} AHJ Permit Milestones
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="swimlane-container flex space-x-4 mt-6">
          {statuses.map((status) => (
            <Droppable droppableId={status} key={status}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="swimlane bg-gray-200 p-4 rounded-lg shadow-md w-1/5"
                >
                  <h3 className="text-xl font-bold mb-4">{status}</h3>
                  {filteredTasks
                    .filter((task) => task.status === status)
                    .map((task, index) => (
                      <Draggable
                        key={task._id}
                        draggableId={task._id!}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="task-item bg-white p-4 rounded mb-2 shadow-md"
                          >
                            <h4 className="text-lg font-semibold">
                              {task.scope?.toUpperCase()} - {task.title}
                            </h4>
                            <p className="text-sm">
                              Milestone: {task.milestone?.title}
                            </p>
                            <p className="text-sm">{task.description}</p>
                            <p className="text-sm">Priority: {task.priority}</p>
                            <p className="text-sm flex items-center">
                              <MdPersonOutline
                                style={{ marginRight: '8px', color: 'grey' }}
                              />
                              Assignee: {task.assignee?.firstName}{' '}
                              {task.assignee?.lastName}
                            </p>
                            <div className="flex mt-2 space-x-2">
                              <button
                                onClick={() => onEdit(task)}
                                className="bg-yellow-500 text-white px-4 py-1 rounded hover:bg-yellow-600"
                              >
                                Edit
                              </button>
                              {onDelete && (
                                <button
                                  onClick={() => onDelete(task._id!)}
                                  className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </>
  ) : (
    <></>
  );
};

export default ProjectTaskList;

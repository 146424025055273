import { pdfStyles } from '../../utils/letterAgreement';
import TextList from '../TextList';

function AdditionalServices() {
  return (
    <div>
      <h1 style={pdfStyles.exhibitSmallTitle}>Exhibit B</h1>
      <h1 style={pdfStyles.smallTitle}>Additional Services and Exclusions</h1>
      <br />
      <p>
        This Exhibit B, Additional Services and Exclusions, is incorporated into
        and made a part of the Agreement by and between Abacus Engineering, LLC
        (“Engineer”) and All Clients (“Client”). These Additional Services and
        Exclusions are not included as part of the Scope of Services and Fee as
        listed in the Agreement unless they have been specifically included and
        identified in the Scope of Services.
      </p>
      <br />
      <p>
        However, at the request of the Client, the Engineer can provide the
        following as an additional service or services. This request shall be
        agreed upon in writing by both parties before the services are
        performed.
      </p>
      <br />
      <p>
        Please note that the categories below are for convenience only and that
        additional services and exclusions are valid and applicable regardless
        of category. It is therefore recommended to read all additional services
        and exclusions.
      </p>
      <br />
      <h2>
        <strong>
          Additional Services and Exclusions not included in the Scope of
          Services as outlined in the Agreement:
        </strong>
      </h2>

      <h3>
        <strong>A.</strong> Design-Related Services
      </h3>
      <p>
        - <strong>1</strong> Detailed Traffic Control Plans (other than standard
        details or specifications).
      </p>
      <p>
        - <strong>2</strong> Geotechnical Services other than those listed in
        the Scope of Services.
      </p>
      <p>
        - <strong>3</strong> Significant design revisions following substantial
        completion of the Construction Documents.
      </p>
      <p>
        - <strong>4</strong> Resolve structural implications associated with
        removal of existing retaining walls.
      </p>
      <p>
        - <strong>5</strong> Design of major existing utility relocations or
        modifications, except for minor adjustments.
      </p>
      <p>
        - <strong>6</strong> Design of special improvements (e.g. bioswales,
        bioretention, or permeable pavement) for Low Impact Development (LID).
      </p>
      <p>
        - <strong>7</strong> Preparation of any special interim sets of
        construction documents for phased construction.
      </p>
      <p>
        - <strong>8</strong> Design and/or modifications to existing systems.
      </p>
      <p>
        - <strong>9</strong> Pavement thickness design (Engineer to reference
        Geotech’s Pavement Thickness Recommendation).
      </p>
      <p>
        - <strong>10</strong> Construction sequencing plans.
      </p>
      <p>
        - <strong>11</strong> Gas, telephone, cable and/or electric utility
        design.
      </p>
      <p>
        - <strong>12</strong> Landscape architecture and related details.
      </p>
      <p>
        - <strong>13</strong> Design of wayfinding signs.
      </p>
      <p>
        - <strong>14</strong> Irrigation design.
      </p>
      <p>
        - <strong>15</strong> Fire suppression.
      </p>
      <p>
        - <strong>16</strong> Design of existing ramps and stairs lower than
        adjacent finished grade.
      </p>
      <p>
        - <strong>17</strong> Design of any facilities within the limits of the
        building.
      </p>
      <p>
        - <strong>18</strong> Sanitary sewer lift station design.
      </p>
      <p>
        - <strong>19</strong> Drainage lift station design.
      </p>
      <p>
        - <strong>20</strong> Grease and sand trap design.
      </p>
      <p>
        - <strong>21</strong> Structural design of water meter and valve vaults.
      </p>
      <p>
        - <strong>22</strong> Illumination design or photometric analysis.
      </p>
      <p>
        - <strong>23</strong> Tie-ins of utilities other than storm drain,
        sanitary sewer, and water.
      </p>
      <p>
        - <strong>24</strong> Site engineering on individual parcels as required
        for construction of buildings/homes.
      </p>
      <p>
        - <strong>25</strong> Modification to documents to meet budgeting
        constraints after approval of the Preliminary Plans.
      </p>
      <p>
        - <strong>26</strong> Reverse Osmosis water systems
      </p>
      <p>
        - <strong>27</strong> Hot Water recirculation systems. Standard services
        include hot water point of consumption systems.
      </p>
      <p>
        - <strong>28</strong> Mechanical ERV systems, dehumidifiers,
        humidifiers.
      </p>
      <br />
      <h3>
        <strong>B.</strong> Surveying-Related Services
      </h3>
      <p>
        - <strong>1</strong> The procuring of any temporary or permanent
        easements.
      </p>
      <p>
        - <strong>2</strong> Any SUE (Subsurface Utility Engineering) Survey
        Work.
      </p>
      <p>
        - <strong>3</strong> Construction Staking.
      </p>
      <p>
        - <strong>4</strong> Title Searches.
      </p>
      <p>
        - <strong>5</strong> Application for General Land Office easements.
      </p>

      <br />
      <h3>
        <strong>C.</strong> Bidding-Related Services
      </h3>
      <p>
        - <strong>1</strong> Services related to disputes over
        pre-qualification, bid protests, bid rejection and re-bidding of the
        contract for construction.
      </p>
      <p>
        - <strong>2</strong> Front-end contract documents including General
        Provisions, Special Provisions, and Division 1 specifications, unless
        specified in the Scope of Services.
      </p>
      <p>
        - <strong>3</strong> Bid Evaluation and Management, other than those
        services specified in the Scope of Services.
      </p>

      <br />
      <h3>
        <strong>D.</strong> Construction-Related Services
      </h3>
      <p>
        - <strong>1</strong> Construction management and inspection services.
      </p>
      <p>
        - <strong>2</strong> Performance of materials testing or specialty
        testing services.
      </p>
      <p>
        - <strong>3</strong> Services related to warranty claims, enforcement
        and inspection after final completion.
      </p>
      <p>
        - <strong>4</strong> Services necessary due to the default of the
        Contractor.
      </p>

      <br />
      <h3>
        <strong>E.</strong> Hydraulic and Hydrology-Related Services
      </h3>
      <p>
        - <strong>1</strong> Updating watershed study to reflect new flood
        conditions after the project.
      </p>
      <p>
        - <strong>2</strong> Design of storm water detention facilities, other
        than those specified in the scope of work.
      </p>
      <p>
        - <strong>3</strong> Floodplain studies or reclamation plans.
      </p>
      <p>
        - <strong>4</strong> Hydrologic analysis not specifically included in
        the Scope of Services.
      </p>

      <br />
      <h3>
        <strong>F.</strong> Environmental-Related Services
      </h3>
      <p>
        - <strong>1</strong> Phase I or Phase II Environmental Site Assessment.
      </p>
      <p>
        - <strong>2</strong> Engineering Environmental Assessments.
      </p>
      <p>
        - <strong>3</strong> Storm Water Pollution Prevention Plan (SWPPP),
        SWPPP Book, or state environmental commission Notice of Intent (NOI).
      </p>
      <p>
        - <strong>4</strong> Application to state environmental commission for
        individual water quality certification.
      </p>
      <p>
        - <strong>5</strong> Field survey or analysis required for cultural
        resources investigations.
      </p>
      <p>
        - <strong>6</strong> Preparation of Environmental Information Document,
        Environmental Assessment, or an Environmental Impact Statement.
      </p>
      <p>
        - <strong>7</strong> Presence/absence surveys for federally listed
        threatened/endangered species.
      </p>
      <p>
        - <strong>8</strong> Application for state or federal sand- or
        gravel-related permit(s).
      </p>
      <p>
        - <strong>9</strong> Design associated with soil remediation.
      </p>
      <p>
        - <strong>10</strong> Application for state or federal air-related
        permit(s).
      </p>
      <p>
        - <strong>11</strong> Application for state or federal above ground
        storage tank registration.
      </p>
      <p>
        - <strong>12</strong> Design for mitigation of site trees removed by
        construction.
      </p>
      <p>
        - <strong>13</strong> Design and/or environmental services related to
        the decommissioning of lift station other than a simple note on the
        drawings calling for removal of the existing lift station.
      </p>
      <p>
        - <strong>14</strong> Design of wells or well closures.
      </p>
      <p>
        - <strong>15</strong> Water reuse and wastewater treatment facilities,
        other than those specified in the Scope of Work.
      </p>
      <p>
        - <strong>16</strong> Preparation and filing of wastewater backflow
        release of documents.
      </p>

      <br />
      <h3>
        <strong>G.</strong> Federal Agency-Related Services
      </h3>
      <p>
        - <strong>1</strong> Letter of Map Amendment (LOMA) or Letter of Map
        Revision (LOMR) related services or submissions to the Federal Emergency
        Management Agency (FEMA).
      </p>
      <p>
        - <strong>2</strong> Procuring of any permits required by United States
        Army Corps of Engineers (USACE).
      </p>
      <p>
        - <strong>3</strong> Preparation of a Preconstruction Notification
        (PCN), mitigation plan, or a U.S. Army Corps of Engineers (USACE)
        Section 404 Individual Permit Application is not included in this scope
        of work.
      </p>
      <p>
        - <strong>4</strong> Meetings or consultation with the USACE or other
        resource agencies, except as specifically noted in the scope of
        services.
      </p>
      <p>
        - <strong>5</strong> Section 10 Permitting with the USACE.
      </p>
      <p>
        - <strong>6</strong> Preparation of a jurisdictional determination
        report.
      </p>
      <p>
        - <strong>7</strong> Preparation of a formal written request for USACE
        authorization under a letter of permission procedure.
      </p>
      <p>
        - <strong>8</strong> Consultation with the U. S. Fish and Wildlife
        Service under Section 7 of the Endangered Species act.
      </p>
      <p>
        - <strong>9</strong> Preparation of a mitigation plan to compensate for
        impacts to waters of the U.S., including mitigation monitoring required
        by permit conditions, compliance with permit conditions, or additional
        modifications to the compensatory mitigation plan.
      </p>

      <br />
      <h3>
        <strong>H.</strong> Miscellaneous Services
      </h3>
      <p>
        - <strong>1</strong> Graphic products except as noted in the Scope of
        Services.
      </p>
      <p>
        - <strong>2</strong> Coordination with private utility companies.
      </p>
      <p>
        - <strong>3</strong> Coordination with state or federal transportation
        and transit agencies.
      </p>
      <p>
        - <strong>4</strong> Coordination with, and certifications to, insurance
        companies, attorneys, or banking institutions.
      </p>
      <p>
        - <strong>5</strong> Site visits, workshop, or meetings not specifically
        included in the Scope of Services.
      </p>
      <p>
        - <strong>6</strong> Printing of drawings, specification and contract
        documents except for review sets or as noted herein.
      </p>
      <p>
        - <strong>7</strong> Any additional work not specifically included in
        the Scope of Services will be considered as an Additional Service.
      </p>
      <p>
        - <strong>8</strong> Procuring of any permits required by Authorities
        Having Jurisdiction (AHJs).
      </p>
      <p>
        - <strong>9</strong> Services related to damages caused by fire, flood,
        earthquake or other acts of God.
      </p>
      <p>
        - <strong>10</strong> Expert representation at legal proceedings or at
        contested hearings.
      </p>
      <p>
        - <strong>11</strong> Services to support, prepare, document, bring,
        defend, or assist in litigation undertaken or defended by the Owner.
      </p>

      <br />
    </div>
  );
}

export default AdditionalServices;

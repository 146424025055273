import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeProjectState, projectListState } from '../atoms/project.atom';
import WorkflowStatus from '../components/WorkflowStatus';
import { Form } from '../components/forms/Form';
import {
  DisciplineType,
  Project,
  User,
  classificationOptions,
} from '../models/general';
import { fetchProject, getLinkToken, updateProject } from '../utils/api';
import { workFlowStatuses } from '../utils/misc';
import MessagesList from '../components/MessageList';
import FileUploadModal from '../components/FileUploadComponent';
import FileList from '../components/FileList';
import TeamManagement from '../components/TeamManagement';
import { parseAddress } from 'addresser';
import { formatDate } from 'date-fns';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import MilestoneList from '../components/MilestoneList';

const ProjectView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [linkWithToken, setLinkWithToken] = useState('');
  const { projectId } = useParams<{ projectId: string }>();
  const [projects, setProjects] = useRecoilState(projectListState);
  const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [formData, setFormData] = useState({
    name: activeProject.name || '',
    workFlowStatus: activeProject.workFlowStatus || '',
    uniqueName: activeProject.uniqueName || '',
    classification: activeProject.classification || '',
    estimatedConstructionCost: activeProject.estimatedConstrictionCost || 0,
    description: activeProject.description || '',
    isUnplatted: activeProject.isUnplatted || false,
    unplattedAddress: activeProject.unplattedAddress || '',
    address: activeProject.address || '',
    disciplines: activeProject.disciplines || [],
    client: activeProject.client || '',
    shouldBillOwner: activeProject.shouldBillOwner || false,
    owner: activeProject.owner || '',
    contacts: activeProject.contacts || [],
    workDocuments: activeProject.workDocuments || [],
    clientDocuments: activeProject.clientDocuments || [],
  });

  useEffect(() => {
    const fetchProjectData = async (projectId: string) => {
      console.log('getting project data', projectId);
      try {
        const response = await fetchProject(projectId);
        setActiveProject(response.data);
        setFormData({
          name: response.data.name || '',
          workFlowStatus: response.data.workFlowStatus || '',
          uniqueName: response.data.uniqueName || '',
          classification: response.data.classification || '',
          estimatedConstructionCost:
            response.data.estimatedConstrictionCost || 0,
          description: response.data.description || '',
          isUnplatted: response.data.isUnplatted || false,
          unplattedAddress: response.data.unplattedAddress || '',
          address: response.data.address || '',
          disciplines: response.data.disciplines || [],
          client: response.data.client || '',
          shouldBillOwner: response.data.shouldBillOwner || false,
          owner: response.data.owner || '',
          contacts: response.data.contacts || [],
          workDocuments: response.data.workDocuments || [],
          clientDocuments: response.data.clientDocuments || [],
        });
      } catch (error) {
        console.error('Failed to fetch project', error);
      }
    };
    if (projectId) fetchProjectData(projectId);
  }, []);

  const activeLink =
    'rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto';
  const inactiveLink =
    'rounded bg-white px-4 py-2 text-blue w-60 font-medium mx-auto';
  const updateProjectList = async (project: Project) => {
    if (!('_id' in project)) return;

    const response = await updateProject(project._id, project);
    setActiveProject(response.data);
    const items = [...projects];
    const existingItemIndex = items.findIndex(
      (item) => item._id === project._id,
    );
    if (existingItemIndex !== -1) {
      // Update existing item
      const updatedItems = [...items];
      updatedItems[existingItemIndex] = project;
      setProjects(updatedItems);
    } else {
      setProjects([...items, project]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShareLink = async () => {
    try {
      const response = await getLinkToken({
        email: activeProject.client?.email,
      });
      const builtLink =
        window.location.protocol +
        '//' +
        window.location.host +
        '/guest?token=' +
        response.data.access_token;
      setLinkWithToken(builtLink);
      setShowLink(true);
      console.log(builtLink);
    } catch {
      alert('Token Failed');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkWithToken).then(
      () => {
        setShowLink(false);
      },
      (err) => {
        alert('Failed to copy');
      },
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProject = {
        ...activeProject,
        ...formData,
      };
      const response = await updateProject(projectId, updatedProject);
      setActiveProject(response.data);
      setProjects((projects: any[]) =>
        projects.map((proj) => (proj._id === projectId ? response.data : proj)),
      );
    } catch (error) {
      console.error('Failed to update project', error);
    }
  };

  const handleFileUploadSuccess = async (fileInfo: any) => {
    let updatedProject = {};
    if (fileInfo.source === 'clientDocuments') {
      updatedProject = {
        ...activeProject,
        clientDocuments: [...activeProject?.clientDocuments, fileInfo],
      };
    } else if (fileInfo.source === 'deliverableDocuments') {
      updatedProject = {
        ...activeProject,
        deliverableDocuments: [...activeProject?.workDocuments, fileInfo],
      };
    } else {
      updatedProject = {
        ...activeProject,
        workDocuments: [...activeProject?.workDocuments, fileInfo],
      };
    }
    try {
      const response = await updateProject(activeProject._id, updatedProject);
      setActiveProject(response.data);
      setProjects((projects: any[]) =>
        projects.map((proj) => (proj._id === projectId ? response.data : proj)),
      );
    } catch (error) {
      console.error('Failed to update project', error);
    }
  };

  const formatFolderName = (project: Project) => {
    try {
      const formattedAddress = parseAddress(project.address);
      return (
        formattedAddress.placeName +
        '_' +
        formattedAddress.streetName +
        '_' +
        (project.classification === 'residential'
          ? formattedAddress.streetNumber
          : project.name.slice(0, 15)) +
        '_' +
        project.client.name +
        '_' +
        formatDate(new Date(), 'yyyy')
      );
    } catch {
      return 'N/A the provided address does not included the proper fields.';
    }
  };

  // const tempProject =
  //   projects.find((project: Project) => project._id === projectId) || null;

  if (!activeProject._id) {
    return <div>Project Loading</div>;
  }
  // setActiveProject(tempProject);

  const handleUpdateProject = (updatedProject: any) => {
    setActiveProject(updatedProject);
    updateProject(activeProject._id, updatedProject);
  };
  // Add a new discipline
  const handleAddDiscipline = (discipline: DisciplineType) => {
    if (!formData.disciplines.includes(discipline)) {
      setFormData((prevData) => ({
        ...prevData,
        disciplines: [...prevData.disciplines, discipline],
      }));
    }
  };

  // Remove a discipline
  const handleRemoveDiscipline = (discipline: string) => {
    setFormData((prevData) => ({
      ...prevData,
      disciplines: prevData.disciplines.filter((d) => d !== discipline),
    }));
  };
  const availableDisciplines = Object.values(DisciplineType).filter(
    (discipline) => !formData.disciplines.includes(discipline),
  );

  return (
    <>
      <div className=" bg-white p-6 rounded-lg shadow-lg mt-2">
        <br />

        <form className="w-" onSubmit={handleSubmit}>
          <div className="w-full">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="text-xl font-bold text-center w-full"
            />
          </div>
          <div className="w-full">
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="text-xl text-center w-full"
            />
          </div>
          <div className="w-full">
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="text-xl text-center w-full"
            />
          </div>
          <WorkflowStatus
            statuses={workFlowStatuses}
            currentStatus={activeProject.workFlowStatus}
          />
          {activeAuthorizations.hasEstimating && (
            <div className="grid grid-cols-3">
              <div>
                <label className="font-bold my-4">
                  Class:
                  <select
                    name="classification"
                    value={formData.classification}
                    onChange={handleChange}
                  >
                    {classificationOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </label>
                <br style={{ height: '20px' }} />
                <label className="font-bold my-4">
                  Status:
                  <select
                    name="workFlowStatus"
                    value={formData.workFlowStatus}
                    onChange={handleChange}
                  >
                    {workFlowStatuses.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="col-span-1"></div>
              <div className="">
                <label
                  htmlFor="disciplines"
                  className="block text-sm font-medium text-gray-700"
                >
                  Add/Remove Disciplines
                </label>

                <div className="mt-1 flex">
                  <select
                    id="discipline"
                    className="block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) => {
                      handleAddDiscipline(e.target.value as DisciplineType);
                      e.target.value = '';
                    }}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select a discipline
                    </option>
                    {availableDisciplines.map((discipline) => (
                      <option key={discipline} value={discipline}>
                        {discipline}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Display selected disciplines */}
                <ul className="mt-2 space-y-1">
                  {formData.disciplines.map((discipline, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span>
                        {discipline === 'admin'
                          ? 'Construction Admin'
                          : discipline}
                      </span>
                      <button
                        type="button"
                        onClick={() => handleRemoveDiscipline(discipline)}
                        className="text-red-600 hover:text-red-900 text-sm"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save Changes
          </button>
        </form>
        <div className="grid grid-cols-3">
          <div className="col-span-1 px-1 ">
            <h2 className="text-xl font-bold">Client</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p className="font-bold">{activeProject.client?.name}</p>
              <p>{activeProject.client?.address}</p>
              <p>{activeProject.client?.email}</p>
              <p>{activeProject.client?.phone}</p>
            </div>
            <div className="my-1 grid grid-cols-3">
              <button
                type="submit"
                className="bg-blue-500 text-white px-2 py-1 rounded"
                onClick={() => {
                  return;
                }}
              >
                Call
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white col-start-3 px-2 py-1 rounded"
                onClick={() => handleShareLink()}
              >
                Send Login Link
              </button>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Details</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p className="capitalize">
                <span className="font-bold">Quoted Disciplines: </span>
                {activeProject?.disciplines?.map(
                  (discipline) => discipline + ' ',
                )}
              </p>
              <p className="capitalize">
                <span className="font-bold">Project Folder Name: </span>
                {formatFolderName(activeProject)}
              </p>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Milestones</h2>
            <div className="border-gray-400 h-56 border rounded p-4 overflow-auto">
              <MilestoneList project={activeProject} isViewOnly={false} />
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Team</h2>
            <div className="border-gray-400 h-56 border rounded p-4 overflow-auto">
              <TeamManagement
                project={activeProject}
                companyId={activeProject.company._id || activeProject.company}
                updateProject={handleUpdateProject}
              />
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Files</h2>
            <div className="border-gray-400 h-56 border rounded p-4 overflow-auto">
              <FileList
                heading={'Work Files'}
                files={activeProject.workDocuments}
              />
              <FileList
                heading={'Client Visible Uploads'}
                files={activeProject.clientDocuments}
              />
              <FileList
                heading={'Client Deliverables'}
                files={activeProject.deliverableDocuments}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-2 py-1 my-1 rounded"
              onClick={() => setIsModalOpen(true)}
            >
              + Upload File
            </button>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Conversations</h2>
            <MessagesList />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 content-center mt-2">
        {activeAuthorizations.hasEstimating && (
          <NavLink
            className={({ isActive }) => (isActive ? activeLink : inactiveLink)}
            to="loe"
          >
            LOE - Level of Effort
          </NavLink>
        )}
        {activeAuthorizations.hasLegal && (
          <NavLink
            className={({ isActive }) => (isActive ? activeLink : inactiveLink)}
            to="agreement"
          >
            Letter Agreement
          </NavLink>
        )}
        {activeAuthorizations.hasDesign && (
          <NavLink
            className={({ isActive }) => (isActive ? activeLink : inactiveLink)}
            to="schedule"
          >
            Schedule
          </NavLink>
        )}
      </div>
      <FileUploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectId={activeProject._id}
        companyId={activeProject.company._id || activeProject.company}
        onFileUploadSuccess={handleFileUploadSuccess}
      />
      {showLink && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-gray-200 rounded-lg shadow-lg p-6 w-3/4 relative">
            <h2 className="text-2xl font-bold mb-4">Sharable Link</h2>
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowLink(false)}
            >
              &times;
            </button>
            <p>This will provide a link to this client's dashboard: </p>
            <div className="overflow-ellipsis bg-white p-2 rounded">
              {linkWithToken.slice(0, 40)}...
            </div>
            <button
              onClick={copyToClipboard}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
      )}
      <Outlet context={{ activeProject, updateProjectList }} />
    </>
  );
};
export default ProjectView;

import React, { useState } from 'react';
import { requestPasswordReset } from '../utils/api';

const RequestPasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset(email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setMessage('Error sending password reset email.');
    }
  };

  return (
    <div className="bg-gray-200 h-screen p-40">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h1 className="my-4 text-xl font-bold text-center">
          Request Password Reset
        </h1>
        <form
          className="grid grid-cols-1 content-center"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="my-4 block w-2/3 mx-auto rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            required
          />
          <button
            type="submit"
            className="my-4 rounded bg-blue-500 px-4 py-2 text-white w-2/3 font-medium mx-auto hover:bg-blue-600"
          >
            Send Reset Email
          </button>
        </form>
        {message && <p className="text-center">{message}</p>}
      </div>
    </div>
  );
};

export default RequestPasswordResetPage;

import React, { createContext, useContext, useState } from 'react';
import { ErrorModal } from '../components/ErrorModal';

export interface ErrorType {
  code?: number;
  statusText?: string;
  context?: string;
}

interface ErrorContextType {
  showError: (error: ErrorType) => void;
  hideError: () => void;
  isErrorVisible: boolean;
  currentError: ErrorType | null;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export function ErrorProvider({ children }: { children: React.ReactNode }) {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [currentError, setCurrentError] = useState<ErrorType | null>(null);

  const showError = (error: ErrorType) => {
    setCurrentError(error);
    setIsErrorVisible(true);
  };

  const hideError = () => {
    setIsErrorVisible(false);
    setCurrentError(null);
  };

  return (
    <ErrorContext.Provider
      value={{ showError, hideError, isErrorVisible, currentError }}
    >
      {children}
      <ErrorModal
        isOpen={isErrorVisible}
        onClose={hideError}
        error={currentError || undefined}
      />
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
}

import React, { useState, useEffect } from 'react';
import { Button, Select, Input, message } from 'antd';
import { useRecoilValue } from 'recoil';
import { activeCompanyState } from '../atoms/new.company.atom';
import { createMetrics, getMetrics } from '../utils/api';

interface Metric {
  id: string;
  milestone: string;
  displayName: string;
  operation: 'count' | 'sum';
  comparison: 'greater' | 'less' | 'equal';
  target: number;
  includeAmount?: boolean;
  amountTarget?: number;
  timeFrame: 'week' | 'month' | 'quarter' | 'year';
}

const MILESTONE_OPTIONS = [
  'Estimate Completed',
  'Letter Agreement Signed',
  'Paid Deposit',
  'Schematic Delivery',
  '100% Plans Delivery',
  'AHJ Permit Approved',
  'Work Started',
];

const TIME_FRAME_OPTIONS = [
  { value: 'week', label: 'Per Week' },
  { value: 'month', label: 'Per Month' },
  { value: 'quarter', label: 'Per Quarter' },
  { value: 'year', label: 'Per Year' },
];

const MetricsForm: React.FC = () => {
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [loading, setLoading] = useState(false);
  const activeCompany = useRecoilValue(activeCompanyState);

  useEffect(() => {
    fetchMetrics();
  }, []);

  const fetchMetrics = async () => {
    try {
      setLoading(true);
      const response = await getMetrics();
      const metricsWithIds = response.data.map((metric) => ({
        ...metric,
        id: metric._id,
      }));
      setMetrics(metricsWithIds);
    } catch (error) {
      message.error('Failed to load metrics');
      console.error('Failed to load metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  const addMetric = () => {
    const newMetric: Metric = {
      id: Date.now().toString(),
      milestone: '',
      displayName: '',
      operation: 'count',
      comparison: 'greater',
      target: 0,
      timeFrame: 'month',
    };
    setMetrics([...metrics, newMetric]);
  };

  const updateMetric = (id: string, updates: Partial<Metric>) => {
    setMetrics(metrics.map((m) => (m.id === id ? { ...m, ...updates } : m)));
  };

  const removeMetric = (id: string) => {
    setMetrics(metrics.filter((m) => m.id !== id));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await createMetrics(metrics);
      message.success('Metrics saved successfully');
    } catch (error) {
      message.error('Failed to save metrics');
      console.error('Failed to save metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-6">Metrics List</h2>

      <div className="space-y-6">
        {metrics.map((metric) => (
          <div
            key={metric.id}
            className="p-4 border rounded-lg flex flex-wrap gap-4 items-center"
          >
            <Input
              value={metric.displayName}
              onChange={(e) =>
                updateMetric(metric.id, { displayName: e.target.value })
              }
              placeholder="Display Name (optional)"
              className="min-w-[200px]"
            />

            <Select
              value={metric.milestone}
              onChange={(value) =>
                updateMetric(metric.id, { milestone: value })
              }
              placeholder="Select Milestone"
              className="min-w-[200px]"
            >
              {MILESTONE_OPTIONS.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={metric.operation}
              onChange={(value) =>
                updateMetric(metric.id, { operation: value })
              }
              className="w-[100px]"
            >
              <Select.Option value="count">Count</Select.Option>
              <Select.Option value="sum">Sum</Select.Option>
            </Select>

            <span>should be</span>

            <Select
              value={metric.comparison}
              onChange={(value) =>
                updateMetric(metric.id, { comparison: value })
              }
              className="w-[120px]"
            >
              <Select.Option value="greater">greater than</Select.Option>
              <Select.Option value="less">less than</Select.Option>
              <Select.Option value="equal">equal to</Select.Option>
            </Select>

            <Input
              type="number"
              value={metric.target}
              onChange={(e) =>
                updateMetric(metric.id, { target: Number(e.target.value) })
              }
              className="w-[100px]"
              prefix={metric.operation === 'sum' ? '$' : ''}
            />

            <Select
              value={metric.timeFrame}
              onChange={(value) =>
                updateMetric(metric.id, { timeFrame: value })
              }
              className="w-[140px]"
            >
              {TIME_FRAME_OPTIONS.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>

            {metric.operation === 'count' && (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={metric.includeAmount}
                  onChange={(e) =>
                    updateMetric(metric.id, { includeAmount: e.target.checked })
                  }
                />
                <span>Include amount target</span>
                {metric.includeAmount && (
                  <>
                    <span>of $</span>
                    <Input
                      type="number"
                      value={metric.amountTarget}
                      onChange={(e) =>
                        updateMetric(metric.id, {
                          amountTarget: Number(e.target.value),
                        })
                      }
                      className="w-[100px]"
                    />
                  </>
                )}
              </div>
            )}

            <Button
              danger
              onClick={() => removeMetric(metric.id)}
              className="ml-auto"
            >
              Remove
            </Button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex gap-4">
        <Button type="primary" onClick={addMetric}>
          Add Metric
        </Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          Save Metrics
        </Button>
      </div>
    </div>
  );
};

export default MetricsForm;

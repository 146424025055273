import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
  Project,
  classificationOptions,
  DisciplineType,
  LOEItemI,
} from '../../models/general';
import { createProject, fetchCompanies } from '../../utils/api';
import { useRecoilValue } from 'recoil';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal';
import CreateClientCompanyForm from './CreateClientCompanyForm';
import SelectItemComponent from '../Selector';
import { LOETable, LOEtemplates } from '../../utils/misc';

const CreateProjectForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Project>({
    defaultValues: {
      name: '',
      classification: '',
      description: '',
      isUnplatted: false,
      unplattedAddress: '',
      address: '',
      disciplines: [],
      client: '',
      shouldBillOwner: false,
    },
  });
  const company = useRecoilValue(activeCompanyState);
  const [query, setQuery] = useState('');
  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateSelection, setTemplateSelection] = useState<string>('');
  const navigate = useNavigate();

  const handleSelectionChange = (newSelection: string) => {
    setTemplateSelection(newSelection);
  };
  const fetchClientsList = async () => {
    try {
      const response = await fetchCompanies();
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);
    await fetchClientsList();
  };

  useEffect(() => {
    fetchClientsList();
  }, []);

  const filteredclients = clients.filter((client: any) =>
    client.name.toLowerCase().includes(query.toLowerCase()),
  );

  const onSubmit = async (data: Project) => {
    try {
      const workItems: LOEItemI[] = [];
      let loeTable = LOETable;
      if (templateSelection.length > 0) {
        loeTable = (LOEtemplates as any)[templateSelection];
      }
      data.disciplines.forEach((discipline) => {
        (loeTable as any)[discipline].lineItems.forEach((loeItem: LOEItemI) => {
          const newItem = {
            discipline,
            ...loeItem,
          };
          workItems.push(newItem);
        });
      });
      const projectData = {
        ...data,
        company: company._id,
        workFlowStatus: 'initiated',
        loeEstimate: workItems,
      };
      const response = await createProject(projectData);
      console.log('Project created successfully:', response.data);
      navigate(`/project/${response.data._id}/schedule`);
    } catch (error) {
      console.error('Error creating project:', error);
      // Handle error appropriately
    }
  };

  const clientOptions = clients.map((client: any) => ({
    value: client,
    label: `${client.name} - ${client.address || 'No address'}`,
  }));

  const disciplineOptions = Object.values(DisciplineType).map((discipline) => ({
    value: discipline,
    label:
      discipline === 'admin'
        ? 'Construction Admin'
        : discipline.charAt(0).toUpperCase() + discipline.slice(1),
  }));

  const availableDisciplines = Object.values(DisciplineType);

  const handleSelectAllDisciplines = () => {
    setValue('disciplines', availableDisciplines);
  };

  // Tailwind-based styles
  const customStyles = {
    control: (base: any) => ({
      ...base,
      '@media (min-width: 640px)': {
        minHeight: '42px',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgb(59 130 246)' // bg-blue-500
        : state.isFocused
          ? 'rgb(239 246 255)' // bg-blue-50
          : 'white',
      color: state.isSelected ? 'white' : 'rgb(55 65 81)', // text-gray-700
      cursor: 'pointer',
      ':active': {
        backgroundColor: 'rgb(59 130 246)', // bg-blue-500
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'rgb(239 246 255)', // bg-blue-50
      borderRadius: '0.375rem', // rounded
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'rgb(59 130 246)', // text-blue-500
      fontWeight: '500', // font-medium
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: 'rgb(59 130 246)', // text-blue-500
      ':hover': {
        backgroundColor: 'rgb(219 234 254)', // bg-blue-100
        color: 'rgb(37 99 235)', // text-blue-600
      },
    }),
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <CreateClientCompanyForm onClose={handleModalClose} />
      </Modal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-6 bg-white rounded-lg shadow-lg"
      >
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Project Name
          </label>
          <input
            type="text"
            {...register('name', { required: 'Project name is required' })}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {errors.name && <p className="text-red-500">{errors.name.message}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Classification
          </label>
          <Controller
            control={control}
            name="classification"
            rules={{ required: 'Classification is required' }}
            render={({ field }) => (
              <select
                {...field}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Classification</option>
                {classificationOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
          />
          {errors.classification && (
            <p className="text-red-500">{errors.classification.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Address
          </label>
          <input
            type="text"
            {...register('address', { required: 'Address is required' })}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {errors.address && (
            <p className="text-red-500">{errors.address.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Description
          </label>
          <textarea
            {...register('description', {
              required: 'Description is required',
            })}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {errors.description && (
            <p className="text-red-500">{errors.description.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600 mb-2">
            Disciplines
          </label>

          <Controller
            control={control}
            name="disciplines"
            render={({ field }) => (
              <div className="relative">
                <Select
                  isMulti
                  options={disciplineOptions}
                  value={disciplineOptions.filter((option) =>
                    field.value.includes(option.value),
                  )}
                  onChange={(selectedOptions) => {
                    field.onChange(
                      selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [],
                    );
                  }}
                  styles={customStyles}
                  className="w-full"
                  classNamePrefix="select"
                  placeholder="Select disciplines..."
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'rgb(59 130 246)', // blue-500
                      primary75: 'rgb(96 165 250)', // blue-400
                      primary50: 'rgb(219 234 254)', // blue-100
                      primary25: 'rgb(239 246 255)', // blue-50
                    },
                  })}
                />
              </div>
            )}
          />
          <div className="flex items-center mb-2">
            <button
              type="button"
              onClick={handleSelectAllDisciplines}
              className="text-sm text-blue-500 hover:text-blue-700"
            >
              Select All
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Client
          </label>
          <div className="flex items-center gap-2 mb-2">
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="text-sm text-blue-500 hover:text-blue-700 flex items-center"
            >
              <span className="mr-1">+</span> Create New Client
            </button>
          </div>
          <Controller
            control={control}
            name="client"
            rules={{ required: 'Client is required' }}
            render={({ field }) => (
              <div>
                <Select
                  options={clientOptions}
                  styles={customStyles}
                  value={clientOptions.find(
                    (option) => option.value === field.value,
                  )}
                  onChange={(option) => field.onChange(option?.value)}
                  isClearable
                  isSearchable
                  placeholder="Select a client..."
                  noOptionsMessage={() => (
                    <div
                      className="cursor-pointer text-blue-500 hover:text-blue-700"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Create a new client
                    </div>
                  )}
                />
              </div>
            )}
          />
          {errors.client && (
            <p className="text-red-500">{errors.client.message}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Should Bill Owner
          </label>
          <input
            type="checkbox"
            {...register('shouldBillOwner')}
            className="ml-2"
          />
        </div>
        <div className="col-span-2 grid content-center mt-2">
          <label className="block text-sm font-medium leading-6 text-gray-600">
            Start with an LOE Template?
          </label>
          <SelectItemComponent
            selection={templateSelection}
            onSelectionChange={handleSelectionChange}
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Create Project
        </button>
      </form>
    </>
  );
};

export default CreateProjectForm;

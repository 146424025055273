import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { LOEItemI } from '../models/general';
import { hourlyRates } from '../utils/misc';
import LOEItem from './LOEItem';

interface Props {
  discipline: string;
  items: LOEItemI[];
  isEstimating: boolean;
  disciplineTotal: number;
  onAddItem: (discipline: string) => void;
  onUpdateItem: (index: number, item: LOEItemI) => void;
  onRemoveItem: (index: number) => void;
}

const LOEDisciplineGroup: React.FC<Props> = ({
  discipline,
  items,
  isEstimating,
  disciplineTotal,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
}) => {
  const disciplineItems = items.filter(
    (item) => item.discipline === discipline,
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
      <div className="text-gray-600 text-xl font-semibold capitalize border-b-2 mb-4">
        {discipline} - ${disciplineTotal}
      </div>

      <div className="grid grid-cols-12 gap-2 mb-2 font-medium text-gray-700">
        <div className="col-span-1"></div>
        <div className="col-span-4">Task Description</div>
        <div className="col-span-1 px-4 text-center">Total</div>
        <div className="col-span-1 px-4 text-center">
          PE Hours (${hourlyRates.pe}/hr)
        </div>
        <div className="col-span-1 px-4 text-center">
          EIT Hours (${hourlyRates.eit}/hr)
        </div>
        <div className="col-span-1 px-4 text-center">
          Draftsman Hours (${hourlyRates.draftsman}/hr)
        </div>
        <div className="col-span-1 px-4 text-center">Sub Fees</div>
        <div className="col-span-1 px-4 text-center">Mileage Cost</div>
        <div className="col-span-1"></div>
      </div>

      <Droppable droppableId={discipline}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="space-y-2"
          >
            {isEstimating &&
              disciplineItems.map((item, index) => {
                const actualIndex = items.findIndex(
                  (fullItem) => fullItem === item,
                );
                return (
                  <Draggable
                    key={`${discipline}-${actualIndex}`}
                    draggableId={`${discipline}-${actualIndex}`}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <LOEItem
                          item={item}
                          index={actualIndex}
                          onChange={onUpdateItem}
                          onRemove={onRemoveItem}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {isEstimating && (
        <div
          className="rounded bg-gray-500 px-4 py-1 text-white w-20 font-small my-2 cursor-pointer"
          onClick={() => onAddItem(discipline)}
        >
          + Item
        </div>
      )}

      {!isEstimating && (
        <h1 className="col-span-6 text-2xl">
          This Estimate has been Finalized and is no longer editable.
        </h1>
      )}
    </div>
  );
};

export default LOEDisciplineGroup;

// src/../pages/LoginPage.tsx

import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { signin } from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { activeCompanyState } from '../atoms/new.company.atom';
import useAuth from '../hooks/useAuth';
import { Company } from '../models/general';

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const location = useLocation();
  const { setToken } = useAuth();
  const from: string = location.state?.from?.pathname || '/projectList';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isSubscriber = (activeCompany: Company) => {
    return (
      activeCompany &&
      activeCompany.subscriptionLevel &&
      activeCompany.subscriptionLevel !== 'Guest'
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await signin(formData);
      const token = response.data.access_token;
      const decodedToken = jwtDecode(token);

      setToken(token);
      await setActiveUser(decodedToken.user);
      await setActiveCompany(decodedToken.company);
      console.log('from test', from);
      if (!isSubscriber(decodedToken.company) && !from.includes('sign-', -1)) {
        console.log('Guest', from);
        navigate('/guest', { replace: true });
      } else {
        console.log('from', from);
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error('Signin failed', error.message);
      setErrorMessage(error.message);
    }
  };

  const handleGoogleSuccess = (response) => {
    const user = jwtDecode(response.credential);
    setActiveUser(user);
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Login failed', error);
  };

  const handlePasswordReset = () => {
    navigate('/request-password-reset');
  };

  return (
    <div className="bg-gray-200 h-screen p-40">
      <div className=" bg-white p-6 rounded-lg shadow-lg">
        <h1 className="my-4 text-xl font-bold text-center">
          Please Sign In To Get Started
        </h1>
        <form
          className="grid grid-cols-1 content-center"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="my-4 block w-2/3 mx-auto rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            value={formData.username}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="my-4 w-2/3 block mx-auto rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            value={formData.password}
            onChange={handleChange}
          />
          {errorMessage && (
            <p className="text-red-400 mx-auto">
              {errorMessage}. Check your username and password.
            </p>
          )}
          <button
            type="submit"
            className="my-4 rounded bg-blue-500 px-4 py-2 text-white w-2/3 font-medium mx-auto"
          >
            Signin
          </button>
          <button
            onClick={handlePasswordReset}
            className="my-4 rounded bg-gray-500 px-4 py-2 text-white w-2/3 font-medium mx-auto hover:bg-gray-600"
          >
            Help Me Reset My Password
          </button>
        </form>
        {/* <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
          />
        </GoogleOAuthProvider> */}
      </div>
    </div>
  );
};

export default LoginPage;

// src/../components/CreateClientCompanyForm.tsx

import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Select from 'react-select';
import { companyListState } from '../../atoms/new.company.atom';
import {
  createCompany,
  createUser,
  fetchCompanies,
  updateUser,
} from '../../utils/api';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { CompanyType } from '../../models/general';

const CreateClientCompanyForm = ({ onClose }) => {
  const createdBy = useRecoilValue(activeCompanyState);
  const [formData, setFormData] = useState({
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    logoUrl: '',
    website: '',
    companyType: '',
  });

  const [companyList, setCompanyList] = useRecoilState(companyListState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, companyType: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await createUser({
        firstName: formData.firstname,
        userType: 'Customer',
        lastName: formData.lastname,
        username: formData.email,
        email: formData.email,
        phone: formData.phone,
        active: false,
      });
      const company = {
        ...formData,
        owner: user.data._id,
        createdBy: createdBy._id,
      };
      const response = await createCompany(company);
      try {
        const listRes = await fetchCompanies();
        setCompanyList(listRes.data);
      } catch {
        alert('Client List failed to update');
      }

      await updateUser(user.data._id, { company: response.data._id });
      onClose();
    } catch (error) {
      console.error('Company creation failed', error);
    }
  };

  const companyTypeOptions = Object.values(CompanyType).map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-gray-600 text-2xl font-semibold mb-4">
        Create Client Company
      </h2>
      <div>
        <label>Company Name:</label>
        <input
          type="text"
          name="name"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">
          Company Type
        </label>
        <Select
          options={companyTypeOptions}
          onChange={handleSelectChange}
          className="w-full"
          placeholder="Select a company type"
        />
      </div>
      <div>
        <label>Company Email:</label>
        <input
          type="email"
          name="email"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>First Name:</label>
        <input
          type="text"
          name="firstname"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.firstname}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Last Name:</label>
        <input
          type="text"
          name="lastname"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.lastname}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Phone:</label>
        <input
          type="text"
          name="phone"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Address:</label>
        <input
          type="text"
          name="address"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          value={formData.address}
          onChange={handleChange}
          required
        />
      </div>
      <div className="flex justify-center gap-4">
        <button
          className="rounded bg-blue-500 px-4 py-2 text-white w-40 font-medium mx-auto my-4"
          type="submit"
        >
          Create Company
        </button>
        <button
          className="rounded bg-gray-500 px-4 py-2 text-white w-40 font-medium mx-auto my-4"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CreateClientCompanyForm;

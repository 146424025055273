import CreateProjectForm from '../components/forms/CreateProjectForm';

const CreateProjectPage = () => {
  return (
    <>
      <h1 className="text-2xl font-bold mb-6">Create New Project</h1>
      <CreateProjectForm />
    </>
  );
};
export default CreateProjectPage;

import React, { useState, useEffect } from 'react';
import { LOEItemI } from '../models/general';
import { calculateItemTotal } from '../utils/misc';
import { MdDragIndicator, MdDelete } from 'react-icons/md';

interface Props {
  item: LOEItemI;
  index: number;
  onChange: (index: number, item: LOEItemI) => void;
  onRemove: (index: number) => void;
  dragHandleProps?: any;
}

const LOEItem: React.FC<Props> = ({
  item,
  index,
  onChange,
  onRemove,
  dragHandleProps,
}) => {
  const [localItem, setLocalItem] = useState(item);
  const [total, setTotal] = useState(calculateItemTotal(item));

  // Reset local state when item prop changes
  useEffect(() => {
    setLocalItem(item);
    setTotal(calculateItemTotal(item));
  }, [item]);

  const handleChange = (field: keyof LOEItemI, value: string | number) => {
    const updatedItem = {
      ...localItem,
      [field]: typeof value === 'string' ? value : Number(value),
    };
    setLocalItem(updatedItem);
    setTotal(calculateItemTotal(updatedItem));
    onChange(index, updatedItem);
  };

  return (
    <div className="grid grid-cols-12 gap-2 bg-gray-50 p-2 rounded-lg items-center group hover:bg-gray-100">
      <div
        className="col-span-1 flex items-center justify-center cursor-grab active:cursor-grabbing"
        {...dragHandleProps}
      >
        <MdDragIndicator className="h-5 w-5 text-gray-400 group-hover:text-gray-600" />
      </div>
      <div className="col-span-4">
        <input
          type="text"
          value={localItem.title}
          onChange={(e) => handleChange('title', e.target.value)}
          className="w-full p-1 border rounded"
        />
      </div>
      <div className="col-span-1 px-4 text-center">${total}</div>
      <div className="col-span-1 px-4">
        <input
          type="number"
          value={localItem.hoursPE}
          onChange={(e) => handleChange('hoursPE', e.target.value)}
          className="w-full p-1 border rounded text-center"
        />
      </div>
      <div className="col-span-1 px-4">
        <input
          type="number"
          value={localItem.hoursEIT}
          onChange={(e) => handleChange('hoursEIT', e.target.value)}
          className="w-full p-1 border rounded text-center"
        />
      </div>
      <div className="col-span-1 px-4">
        <input
          type="number"
          value={localItem.hoursDraftsman}
          onChange={(e) => handleChange('hoursDraftsman', e.target.value)}
          className="w-full p-1 border rounded text-center"
        />
      </div>
      <div className="col-span-1 px-4">
        <input
          type="number"
          value={localItem.dollarsSub}
          onChange={(e) => handleChange('dollarsSub', e.target.value)}
          className="w-full p-1 border rounded text-center"
        />
      </div>
      <div className="col-span-1 px-4">
        <input
          type="number"
          value={localItem.dollarsMilage}
          onChange={(e) => handleChange('dollarsMilage', e.target.value)}
          className="w-full p-1 border rounded text-center"
        />
      </div>
      <div className="col-span-1 flex justify-center">
        <button
          onClick={() => onRemove(index)}
          className="text-red-500 hover:text-red-700"
          title="Delete item"
        >
          <MdDelete className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default LOEItem;

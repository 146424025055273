// src/api.ts

import axios from 'axios';
import { getToken, clearToken } from '../utils/tokenManager';
import useAuth from '../hooks/useAuth';
import { useError } from '../context/ErrorContext';

// Create a function to handle API errors consistently
export const createApiInstance = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Request interceptor remains the same
  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token && token.length > 0) {
        config.headers = { Authorization: `Bearer ${token}` };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Response interceptor with error handling
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorData = {
        code: error.response?.status,
        statusText: error.response?.statusText || 'Error',
        context:
          error.response?.data?.message || 'An unexpected error occurred',
      };

      window.dispatchEvent(
        new CustomEvent('api-error', {
          detail: errorData,
        }),
      );

      return Promise.reject(errorData);
    },
  );

  return api;
};

const api = createApiInstance();

export const fetchUsers = () => api.get('/users');
export const createUser = (data: {}) => api.post('/users', data);
export const fetchUser = (id: string) => api.get(`/users/${id}`);
export const fetchUsersByCompany = (id: string) =>
  api.get(`/users/company/${id}`);
export const updateUser = (id: string, data: {}) =>
  api.put(`/users/${id}`, data);
export const deleteUser = (id: string) => api.delete(`/users/${id}`);

export const fetchProjects = () => api.get('/projects');
export const fetchProjectsByCompany = (id: string) =>
  api.get(`/projects/company/${id}`);
export const fetchProjectsByClient = (id: string) =>
  api.get(`/projects/client/${id}`);
export const createProject = (data: {}) => api.post('/projects', data);
export const fetchProject = (id: string) => api.get(`/projects/${id}`);
export const updateProject = (id: string, data: {}) =>
  api.put(`/projects/${id}`, data);
export const deleteProject = (id: string) => api.delete(`/projects/${id}`);

export const createThread = (projectId: string, threadTitle: string) =>
  api.post(`/projects/${projectId}/threads`, {
    title: threadTitle,
  });

export const sendMessage = (
  projectId: string,
  threadId: string,
  message: string,
  notify: boolean,
  isLetterAgreement: boolean,
) =>
  api.post(
    `/projects/${projectId}/threads/${threadId}/messages?sendEmail=${notify}${isLetterAgreement ? '&isLetterAgreement=true' : ''}`,
    {
      content: message,
    },
  );

export const fetchMessages = (
  projectId: string,
  threadId: string,
  page?: number,
  pageSize?: number,
) =>
  api.get(
    `projects/${projectId}/threads/${threadId}?${page ? 'page=' + page : ''}${page ? '&pageSize=' + pageSize : ''}`,
  );

export const fetchCompanies = () => api.get('/companies');
export const createCompany = (data: {}) => api.post('/companies', data);
export const fetchCompany = (id: string) => api.get(`/companies/${id}`);
export const updateCompany = (id: string, data: {}) =>
  api.put(`/companies/${id}`, data);
export const deleteCompany = (id: string) => api.delete(`/companies/${id}`);

export const signup = (data: {}) => api.post('/auth/signup', data);
export const signin = (data: {}) => api.post('/auth/signin', data);
export const getLinkToken = (data: {}) => api.post('/auth/getlinktoken', data);
export const checkLinkToken = (data: {}) =>
  api.post('/auth/checklinktoken', data);

export const uploadFile = async (
  projectId: string,
  companyId: string,
  file: File,
  fileName: string,
  description: string,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  formData.append('description', description);

  return api.post(
    `/file-upload?projectId=${projectId}&companyId=${companyId}`,
    formData,
  );
};
export const getSignedUrl = (data: {}) =>
  api.post(`/file-upload/signed-url`, data);

// Milestone API functions
export const createMilestone = (data: {}) => api.post('/milestone', data);
export const fetchMilestones = () => api.get('/milestone');
export const fetchMilestone = (id: string) => api.get(`/milestone/${id}`);
export const fetchMilestonesByProject = (projectId: string) =>
  api.get(`/milestone/project/${projectId}`);
export const updateMilestone = (id: string, data: {}) =>
  api.patch(`/milestone/${id}`, data);
export const deleteMilestone = (id: string) => api.delete(`/milestone/${id}`);

// Task API functions
export const createTask = (data: {}) => api.post('/task', data);
export const fetchTasksByProject = (projectId: string) =>
  api.get(`/task/project/${projectId}`);
export const fetchTasksByAssignee = (userId: string) =>
  api.get(`/task/assignee/${userId}`);
export const fetchTasksByCompany = (companyId: string) =>
  api.get(`/task/company/${companyId}`);
export const updateTask = (id: string, data: {}) =>
  api.patch(`/task/${id}`, data);
export const deleteTask = (id: string) => api.delete(`/task/${id}`);

// Template API functions
export const createTemplate = (data: {}) => api.post('/template', data);
export const fetchTemplates = () => api.get('/template');
export const fetchTemplate = (id: string) => api.get(`/template/${id}`);
export const fetchTemplatesByCompany = (companyId: string) =>
  api.get(`/template/company/${companyId}`);
export const updateTemplate = (id: string, data: {}) =>
  api.put(`/template/${id}`, data);
export const deleteTemplate = (id: string) => api.delete(`/template/${id}`);

// Function to request a password reset email
export const requestPasswordReset = (email: string) =>
  api.post('/users/request-password-reset', { email });

// Function to reset the password using the token
export const resetPassword = (token: string, newPassword: string) =>
  api.post('/users/reset-password', {
    recoveryToken: token,
    newpassword: newPassword,
  });

// Add more API calls as needed...

export const getMetrics = () => {
  return api.get('/metrics');
};

export const createMetrics = (metrics: any[]) => {
  return api.post('/metrics', metrics);
};

export const getMilestoneCounts = (
  date?: string,
  timeFrame?: 'week' | 'month' | 'quarter' | 'year',
) => {
  const params = new URLSearchParams();
  if (date) params.append('date', date);
  if (timeFrame) params.append('timeFrame', timeFrame);

  return api.get(`/metrics/milestone-counts?${params.toString()}`);
};
